
.rdp-nav, .rdp-caption_dropdowns {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.rdp-nav_button svg {
  color: #0000008a;
  height: 12px;
}

.rdp-cell, .rdp-head_cell {
  font-size: 12px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  width: 35;
  height: 35
}

.rdp-head_cell {
  color: #00000099
}

.rdp-caption_label {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
}

.rdp-dropdown {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}

.rdp-day_today:not(.rdp-day_outside) {
  border: 1px solid #0000003b;
  font-weight: unset
}

.rdp-button[disabled]:not(.rdp-day_selected) {
  opacity: 1;
  color: rgba(0, 0, 0, 0.38)
}